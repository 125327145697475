import BlankLayout from './layouts/BlankLayout';
import React from 'react';
import { Switch } from 'react-router-dom';
import AppRoute from "./shared/AppRoute";
import HomePage from './pages/HomePage';
import Sayfa from './pages/ArayuzSayfalar/Sayfa';
import Subeler from './pages/ArayuzSayfalar/Subeler';
import Iletisim from './pages/ArayuzSayfalar/Iletisim';
import InsanKaynaklari from './pages/ArayuzSayfalar/InsanKaynaklari';
import Haberler from './pages/ArayuzSayfalar/Haberler';
import HaberDetay from './pages/ArayuzSayfalar/HaberDetay';
import BayiBasvurusu from './pages/ArayuzSayfalar/BayiBasvurusu';
import KuryeCagir from './pages/ArayuzSayfalar/KuryeCagir';
import KargoTakip from './pages/ArayuzSayfalar/KargoTakip';


export const routes = <Switch>
    <AppRoute layout={BlankLayout} exact path="/anasayfa" component={HomePage} />
    <AppRoute layout={BlankLayout} exact path="/subeler" component={Subeler} />
    <AppRoute layout={BlankLayout} exact path="/haberler" component={Haberler} />
    <AppRoute layout={BlankLayout} exact path="/iletisim" component={Iletisim} />
    <AppRoute layout={BlankLayout} exact path="/kargotakip/:kargotakipno" component={KargoTakip} />
    <AppRoute layout={BlankLayout} exact path="/insankaynaklari" component={InsanKaynaklari} />
    <AppRoute layout={BlankLayout} exact path="/bayilik" component={BayiBasvurusu} />
    <AppRoute layout={BlankLayout} exact path="/kuryecagir" component={KuryeCagir} />
    <AppRoute layout={BlankLayout} exact path="/:sayfakodu/:seoanahtarkelimesi" component={Sayfa} />
    <AppRoute layout={BlankLayout} exact path="/haberler/haberdetay/:id" component={HaberDetay} />
    <AppRoute layout={BlankLayout} exact path="/" component={HomePage} />
</Switch>;