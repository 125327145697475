import React from 'react';
export default class Nav extends React.Component {
    render() {
        if (this.props.AcilisSekli === 1 & !this.props.OzelSayfa) {
            return <li><a href={"/" + this.props.SeoUrl} target="_self" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 1 & this.props.OzelSayfa) {
            return <li><a href={this.props.SeoUrl} target="_self" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 2 & !this.props.OzelSayfa) {
            return <li><a href={"/" + this.props.SeoUrl} target="_blank" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 2 & this.props.OzelSayfa) {
            return <li><a href={this.props.SeoUrl} target="_blank" rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
        else if (this.props.AcilisSekli === 3) {
            return <li><a href={"#" + this.props.SeoUrl} rel="noreferrer">{this.props.EkranBaslik}</a></li>;
        }
    }
}