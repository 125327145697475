import * as React from 'react';
import { firmaid, siteApi, YuklenenLogoUrl } from '../../Utils';
import service from 'axios';
import notify from 'devextreme/ui/notify';
import Logo from '../../components/Header/Logo';
import MenuBtn from '../../components/Header/MenuBtn';
import RightTop from '../../components/Header/RightTop';
import RightBottom from '../../components/Header/RightBottom';
import DetailHeader from '../../components/Header/DetailHeader';
import MainFooter from '../../components/Footer/MainFooter';
import Whatsapp from '../../components/Widgets/Whatsapp';
import DetailLeftCol from '../../components/DetailContainer/DetailLeftCol';
import DetailRightCol from '../../components/DetailContainer/DetailRightCol';
import { Helmet } from 'react-helmet';
import { withRouter } from "react-router";
class Sayfa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            EmailAdresi: "",
            Telefon: "",
            TelefonBitisik: "",
            SiteBaslik: "",
            FacebookAdresi: "",
            YoutubeAdresi: "",
            TwitterAdresi: "",
            InstagramAdresi: "",
            LinkedinAdresi: "",
            DribbleAdresi: "",
            FooterAdres: "",
            FooterCopyright: "",
            FooterArkaPlan: "",
            Koordinat: "",
            Logo: "",
            EbultenMail: null,
            Sayfalar: [],
            DinamikSayfa: {},
            WhatsappTel: "",
            SayfaKodu: this.props.match.params.sayfakodu,
            SayfaIciLink: false
        };
        this.getData();

    }

    //#region form fonksiyonları

    async getData() {
        await this.getSetting()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });

        await this.getSitePage()
            .then(res => {

            })
            .catch(error => {
                console.log(error);
                notify(error, "error", 3000);
            });
    }

    //#endregion


    //#region servis metodları

    async getSitePage() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteSayfa/"+firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        this.setState({
                            Sayfalar: res.data.Data,
                            DinamikSayfa: res.data.Data.length > 0 ? res.data.Data.find(x => x.kodu === this.state.SayfaKodu) : null
                        });
                        resolve("Sayfa bilgileri getirildi.")
                    }
                    else
                        reject(res.data.ErrorMessage);
                }).catch(error => {
                    reject(error);
                });
        })
    }

    async getSetting() {
        return new Promise((resolve, reject) => {
            service.get(siteApi + "sitearayuz/getSiteAyar/"+firmaid, null)
                .then(res => {
                    if (res.data.Success) {
                        const ayar = res.data.Data[0];
                        this.setState({
                            FacebookAdresi: ayar.facebookadresi,
                            TwitterAdresi: ayar.twitteradresi,
                            YoutubeAdresi: ayar.youtubeadresi,
                            LinkedinAdresi: ayar.linkedinadresi,
                            InstagramAdresi: ayar.instagramadresi,
                            Telefon: ayar.telefon,
                            WhatsappTel: ayar.whatsapptel,
                            TelefonBitisik: ayar.telefon.trim(),
                            SayfaIciLink: ayar.sayfaicilink,
                            Logo: ayar.logo,
                            SiteBaslik:ayar.SiteBaslik
                        });
                        resolve("Ayar bilgisi getirildi...");
                    } else {
                        notify(res.data.ErrorMessage, "error", 3000);
                        reject(res.data.ErrorMessage);
                    }
                })
                .catch(error => {
                    reject(error);
                })
        });
    }


    //#endregion

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{this.state.DinamikSayfa.ekranbaslik}</title>
                    <meta name="description" content={this.state.DinamikSayfa.seodetay} />
                </Helmet>
                <section id="detailPage">

                    <div className="headerContainer">
                        <div className="container d-flex align-items-end justify-content-between">
                            <Logo Logo={YuklenenLogoUrl+this.state.Logo} Baslik={this.state.SiteBaslik}></Logo>
                            <div className="headerRight">
                                <MenuBtn />
                                <RightTop
                                    facebookadresi={this.state.FacebookAdresi}
                                    twitteradresi={this.state.TwitterAdresi}
                                    youtubeadresi={this.state.YoutubeAdresi}
                                    linkedinadresi={this.state.LinkedinAdresi}
                                    instagramadresi={this.state.InstagramAdresi}
                                    telefonbitisik={this.state.TelefonBitisik}
                                    telefon={this.state.Telefon}
                                    SayfaIciLink={this.state.SayfaIciLink} />
                                <RightBottom Sayfalar={this.state.Sayfalar} />
                            </div>
                        </div>
                    </div>



                    {/* detailheader başlangıç  */}
                    <DetailHeader Baslik={this.state.DinamikSayfa !== null ? this.state.DinamikSayfa.ekranbaslik : "Tanımsız sayfa"} />
                    {/* detailheader bitiş  */}

                    {/* detailcontainer başlangıç  */}
                    <div className="detailContainer">
                        <div className="container col-two d-flex align-items-start justify-content-between">
                            <DetailLeftCol
                                Menuler={this.state.Sayfalar.filter(x => x.kurumsalsayfa === true).sort((a, b) => (a.sira > b.sira ? 1 : -1))}
                                AktifMenu={this.state.DinamikSayfa.kodu} />
                            <DetailRightCol
                                Detay={this.state.DinamikSayfa.icerik} />
                        </div>
                    </div>
                    {/* detailcontainer bitiş  */}

                    {/* whatsapp başlangıç  */}
                    <Whatsapp whatsapptel={this.state.WhatsappTel}></Whatsapp>
                    {/* whatsapp bitiş  */}

                    {/* footer başlangıç  */}
                    <MainFooter
                        Menuler={this.state.Sayfalar}
                        FacebookAdresi={this.state.FacebookAdresi}
                        TwitterAdresi={this.state.TwitterAdresi}
                        YoutubeAdresi={this.state.YoutubeAdresi}
                        LinkedinAdresi={this.state.LinkedinAdresi}
                        InstagramAdresi={this.state.InstagramAdresi}
                        SayfaIciLink={this.state.SayfaIciLink} />
                    {/* footer bitiş  */}
                </section>
            </React.Fragment>
        );
    }
}
export default withRouter(Sayfa);