import * as React from 'react';
import FooterMenu from './FooterMenu';
import EbultenFormu from '../Forms/EbultenFormu';
import FooterSosyalMedia from './FooterSosyalMedia';
export default class MainFooter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer className="mT120">
                    <div className="footer">
                        <div className="container d-flex justify-content-between">
                            <div className="footerLeft d-flex align-items-start">
                                <FooterMenu Menuler={this.props.Menuler} />
                                <EbultenFormu />
                            </div>
                            <div className="footerRight">
                                <FooterSosyalMedia
                                    FacebookAdresi={this.props.FacebookAdresi}
                                    TwitterAdresi={this.props.TwitterAdresi}
                                    YoutubeAdresi={this.props.YoutubeAdresi}
                                    LinkedinAdresi={this.props.LinkedinAdresi}
                                    InstagramAdresi={this.props.InstagramAdresi}
                                    SayfaIciLink={this.props.SayfaIciLink}
                                />
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}