import * as React from 'react';
export default class HaberDetailLeftCol extends React.Component {

    render() {
        return (
            <React.Fragment>
                <div className="detailLeftCol">
                    <div className="newsGallery">
                        <img src={this.props.Resim} alt="" />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}