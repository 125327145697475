import * as React from "react";
import Slide from './Slide';
import { YuklenenSlaytUrl } from '../../Utils';
export default class Slider extends React.Component {
    render() {
        return (
            <React.Fragment>

                {this.props.Slaytlar
                    .map((data, i) => {
                        return <Slide key={i} AcilisSekli={data.acilissekli} Resim={YuklenenSlaytUrl + data.resim} Baslik={data.baslik} Aciklama={data.aciklama} Url={data.url}></Slide>
                    })}
            </React.Fragment>);
    }
}