import * as React from 'react';
export default class DetailRightCol extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="detailRightCol">
                    <div className="detailHTML" dangerouslySetInnerHTML={{ __html: this.props.Detay }}>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}